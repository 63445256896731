import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
  Form,
  Label,
  Badge,
} from "reactstrap";
import EditIcon from "./../../assets/icons/edit-icon.svg";
import RemoveICon from "./../../assets/icons/remove-icon.svg";
import Select from "react-select";
import OrderModal from "./orderModal";
import DeleteModal from "components/Common/DeleteModal";
import {
  deleteOrder,
  getOrderAllData,
  getOrderAllDataChart,
  updateOrder,
} from "service/orderService";
import { showAlert } from "components/Common/AlertMessage";
import moment from "moment";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { DebounceInput } from "react-debounce-input";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { statusDelete } from "../Common/DropdownGroup";
// import { DateRangePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import viewicon from "./../../assets/icons/view-icon.svg"

const styles = {
  color: "red",
  fontSize: "13px",
};

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Bar, Line, Pie } from "react-chartjs-2";

const OrderTable = (props) => {
  const [orderfilter, setOrderfilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [backUpdata, setBackUpdata] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState([]);
  const [delData, setDelData] = useState("");
  const [orderId, setOrderID] = useState("");
  const [cus_name, setCus_name] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [orderStatus, setOrderStatus] = useState(null);
  const [orderStatusOnly, setOrderstatusOnly] = useState("");
  const [serviecNameOnly, setServiceNameOnly] = useState([]);
  const [valueDatePicker, setValueDatePicker] = useState([null, null]);
  const [optionData, setOptionData] = useState([
    {
      label: "Anti-Catfish Check",
      value: "Anti-Catfish",
    },
    { label: "Criminal Check", value: "Criminal" },
    { label: "Social Check", value: "Social" },
  ]);
  const [checkmargin, setCheckMargin] = useState("0px");
  const [page, setPage] = useState("1");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startPage, setStartPage] = useState(1);
  const [status, setStatus] = useState("");

  const [countShow, setCountShow] = useState(0);

  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [limit, setlimit] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [ProcesserFilter, setProcesserFilter] = useState("");
  const [barchartdata, setbarchartdata] = useState();
  console.log("sbarchartdata", barchartdata);
  const [timeRange, setTimeRange] = useState("daily"); // Track selected range

  useEffect(() => {
    if (
      orderfilter ||
      cus_name ||
      orderId ||
      transactionId ||
      orderStatusOnly ||
      currentPage ||
      status ||
      todate ||
      ProcesserFilter
    ) {
      getOrderAll();
    }
  }, [
    orderfilter,
    cus_name,
    orderId,
    transactionId,
    orderStatusOnly,
    currentPage,
    status,
    todate,
    limit,
    ProcesserFilter,
  ]);
  const getChartData = async () => {
    setLoading(true);
    const resp = await getOrderAllDataChart();
    if (resp.code == 200) {
      setbarchartdata(resp.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getOrderAll = async (pageNo) => {
    let dataBody = {
      orderId: orderId ? orderId : "",
      orderStatus: orderStatusOnly ? orderStatusOnly : "",
      to: todate ? todate : "",
      from: fromdate ? fromdate : "",
      flied: cus_name ? cus_name : "",
      transactionId: transactionId ? transactionId : "",
      user_status: status ? status.value : "",
      processor: ProcesserFilter ? ProcesserFilter.value : "",
      page: currentPage,
      limit: limit,
    };
    setLoading(true);
    const resp = await getOrderAllData(dataBody);
    // console.log("resp o order list",resp)
    if (resp.code == 200) {
      handleApiFetchdata(resp.data);
      setCountShow(resp.count);
      setData(resp.data);
      setTotalPages(resp.totalPages);
    } else {
      setLoading(false);
      setCheckMargin("0px");
    }
  };

  const toggleEditModal = (row, type) => {
    if (type == "edit") {
      setEditModal(true);
      setIsEdit(row);
    } else {
      setDeleteModal(true);
      const delObj = {
        id: row._id,
        name: row.first_name,
      };
      setDelData(delObj);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const resp = await deleteOrder(delData.id);
    if (resp.code == 200) {
      setDeleteModal(false);
      showAlert("success", "Order Deleted Successfully");
      getOrderAll();
    } else {
      showAlert("error", "order not deleted yet !");
      setLoading(false);
    }
  };

  const updateHandler = async (data) => {
    setLoading(true);
    const resp = await updateOrder(data._id, data);
    if (resp.code == 200) {
      setEditModal(false);
      showAlert("success", "Order Updated successfully");
      getOrderAll();
    } else {
      setLoading(false);
      showAlert("error", resp.error.massage || "There Is a error try again");
    }
  };

  const closeModal = () => {
    setEditModal(false);
    setDeleteModal(false);
  };
  const handlePageSizeChange = (selectedOption) => {
    setPageSize(selectedOption.value);
    setlimit(selectedOption.value);
    setCurrentPage(1); // Reset to the first page
    setStartPage(1); // Reset startPage for navigation
  };
  const endPage = Math.min(startPage + 4, totalPages);
  const handleApiFetchdata = (data) => {
    setOrderData(data);
    setLoading(false);
    setCheckMargin("0px");
    let dataCheck = localStorage.getItem("search_filter_order") || {};

    // var checkFilter = null;
    // if (Object.keys(dataCheck)?.length > 0) {
    //   checkFilter = JSON?.parse(dataCheck);
    // } else {
    //   checkFilter = dataCheck;
    // }
    // let arr = data;
    // if (checkFilter?.cus_name) {
    //   let dataone = arr?.filter((w) => {
    //     return (
    //       w?.first_name
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase()) ||
    //       w?.last_name
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase()) ||
    //       w?.phone
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase()) ||
    //       w?.email
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase()) ||
    //       w?.user_id
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase())
    //     );
    //   });
    //   arr = dataone;
    // }

    // if (checkFilter?.orderId) {
    //   let datatwo = arr?.filter((w) => {
    //     return w._id.includes(checkFilter.orderId);
    //   });
    //   arr = datatwo;
    // }

    // if (checkFilter?.transactionId) {
    //   let dataThree = arr?.filter((w) => {
    //     return w?.transaction_id?.includes(checkFilter?.transactionId);
    //   });
    //   arr = dataThree;
    // }

    // if (
    //   checkFilter?.serviecNameOnly &&
    //   checkFilter?.serviecNameOnly?.length > 0
    // ) {
    //   let checkdetails = checkFilter?.serviecNameOnly.map((w) => w.value);
    //   let datafour = [];
    //   arr.map((w) => {
    //     let arrCheck = null;

    //     if (
    //       checkdetails[0] &&
    //       checkdetails?.length == 1 &&
    //       w.order.length == 1
    //     ) {
    //       if (
    //         w?.order[0]?.service_name
    //           ?.toLowerCase()
    //           .includes(checkdetails[0].toLowerCase())
    //       ) {
    //         arrCheck = w;
    //       }
    //     }

    //     if (
    //       checkdetails[1] &&
    //       checkdetails?.length == 2 &&
    //       w.order.length == 2
    //     ) {
    //       if (
    //         (w?.order[0]?.service_name
    //           ?.toLowerCase()
    //           .includes(checkdetails[0].toLowerCase()) &&
    //           w?.order[1]?.service_name
    //             ?.toLowerCase()
    //             .includes(checkdetails[1].toLowerCase())) ||
    //         (w?.order[0]?.service_name
    //           ?.toLowerCase()
    //           .includes(checkdetails[1].toLowerCase()) &&
    //           w?.order[1]?.service_name
    //             ?.toLowerCase()
    //             .includes(checkdetails[0].toLowerCase()))
    //       ) {
    //         arrCheck = w;
    //       }
    //     }
    //     if (
    //       checkdetails[2] &&
    //       checkdetails?.length == 3 &&
    //       w.order.length == 3
    //     ) {
    //       arrCheck = w;
    //     }
    //     if (arrCheck !== null) {
    //       datafour.push(arrCheck);
    //     }
    //   });

    //   arr = datafour;
    // }

    // if (
    //   checkFilter?.orderStatusTwo &&
    //   checkFilter?.orderStatusTwo?.value !== "all Statuses"
    // ) {
    //   let dataFive = arr?.filter((w) => {
    //     //order_status
    //     return w?.payment_status
    //       ?.toLowerCase()
    //       .includes(checkFilter?.orderStatusTwo?.value?.toLowerCase());
    //   });
    //   arr = dataFive;
    // }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handlehSetFilter = (name, data) => {
    let dataCheck = localStorage.getItem("search_filter_order") || "{}";
    var checkFilter = JSON?.parse(dataCheck);

    let makeSetData = {
      orderfilter: checkFilter?.orderfilter || {},
      orderfiltert: checkFilter?.orderfiltert || {},
      cus_name: checkFilter?.cus_name || "",
      orderId: checkFilter?.orderId || "",
      user_status: checkFilter?.user_status || "",
      transactionId: checkFilter?.transactionId || "",
      orderStatusTwo: checkFilter?.orderStatusTwo || {
        label: "All Statuses",
        value: "all Statuses",
      },
      serviecNameOnly: checkFilter?.serviecNameOnly || [],
    };
    makeSetData[name] = data;
    // localStorage.setItem("search_filter_order", JSON.stringify(makeSetData));
  };

  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "_id",
      text: "ORDER ID",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <Link to={`/order/order-details/${row._id}`} className="text-gray">
            {row._id}
          </Link>
        </>
      ),
    },
    {
      dataField: "transaction_id",
      text: "TRANSACTION ID",
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray">
            <i>{row.transaction_id || ""}</i>
          </p>
        </>
      ),
    },
    {
      dataField: "processer",
      text: "PROCESSOR",
      sort: true,
    },
    {
      dataField: "order_status",
      text: "STATUS",
      sort: true,
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.payment_status}
          color={row.badgeClass}
          pill
        >
          {capitalizeFirstLetter(
            row.payment_status == "success"
              ? "Complete"
              : row.payment_status || ""
          )}
        </Badge>
      ),
    },
    {
      dataField: "createdAt",
      text: "SUBMITTED",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>{moment(row?.createdAt).local().format("MM / DD / YYYY,")}</p>
          <p>{moment(row?.createdAt).local().format("hh:mm:ss a")}</p>
        </>
      ),
    },
    {
      dataField: "first_name",
      text: "CUSTOMER",
      formatter: (cellContent, row) => (
        <>
          <p>{row?.user_id}</p>
          <p>{row?.user_details?.email || "User Deleted"}</p>
        </>
      ),
    },
    {
      dataField: "total",
      text: "AMOUNT",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="ps-2">
            {row?.processer === "credit" ? "Free" : `$ ${row?.total}`}
          </p>
        </>
      ),
    },
    {
      dataField: "device_type",
      text: "Device",
      formatter: (cellContent, row) => <p>{row?.device_type}</p>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/order/order-details/${row._id}`} className="text-gray">
            <img title={"View"} src={viewicon} alt="edit icon" />
          </Link>

          <Button
            type="button"
            className="btn-sm btn-icon btn-rounded"
            onClick={() => toggleEditModal(row, "del")}
          >
            <img title={"Delete"} src={RemoveICon} alt="remove icon" />
          </Button>
        </div>
      ),
    },
  ];

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= startPage + 4) {
        setStartPage((prevStartPage) =>
          Math.min(prevStartPage + 1, totalPages - 4)
        );
      }
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setStartPage(Math.max(1, totalPages - 4));
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (currentPage <= startPage) {
        setStartPage((prevStartPage) => Math.max(prevStartPage - 1, 1));
      }
    }
  };
  const handlePreviousLastPage = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  // const endPage = Math.min(startPage + 4, totalPages);
  const toggle = () => {
    setModal(!modal);
  };

  // const [state, setState] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: 'selection',
  // })
  // const [opencaleder, setopencaleder] = useState(false);

  // function handleSelect(item) {
  //   setState(item.selection)
  //   // setopencaleder(false)
  // }

  function handleEvent(event, picker) {}
  function handleCallback(start, end, label) {}

  const [err, setErr] = useState("");

  const handleApply = (event, picker) => {
    const startDate = picker.startDate;
    const endDate = picker.endDate;

    // Check if the selected date range includes future dates
    if (startDate.isSameOrAfter(moment()) || endDate.isSameOrAfter(moment())) {
      // You can notify the user or take other actions
      setErr("Please select a date range that doesn't include future dates.");
      picker.element.val("");
      setFromdate("");
      setTodate("");

      setTimeout(() => {
        setErr("");
      }, 2000);
    } else {
      setErr("");
      picker.element.val(
        `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`
      );
      let sdate = moment(startDate).toDate();
      let edate = moment(endDate).toDate();
      setFromdate(moment(sdate).format("YYYY-MM-DD"));
      setTodate(moment(edate).format("YYYY-MM-DD"));
    }
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
    setFromdate("");
    setTodate("");
  };
  //// graphs

  // Parth Roka 18/11/2024 For Orders chart (graph) daily, weekly, monthy
  const [timeRangeOrder, setTimeRangeOrder] = useState("daily"); // Track selected range
  // useEffect(()=>{
  // fetch(`${baseURL}/admin/v2/order/stats`)
  //   .then((res) => res.json())
  //   .then((res) => console.log("this is graph data", res));
  // },[timeRangeOrder])
  // Static JSON Data
  useEffect(() => {
    getChartData();
  }, [timeRangeOrder]);
  const fullJsonDataOrder = {
    daily: {
      timeRange: "daily",
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      userCounts: [100, 200, 150, 300, 250, 400, 350],
      meta: {
        unit: "days",
        totalUsers: 1750,
        averageUsers: 250,
      },
    },
    weekly: {
      timeRange: "weekly",
      labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
      userCounts: [1200, 1500, 1300, 1700, 1400],
      meta: {
        unit: "weeks",
        totalUsers: 7100,
        averageUsers: 1420,
      },
    },
    monthly: {
      timeRange: "monthly",
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      userCounts: [
        1000, 2000, 1800, 2200, 3000, 2500, 2700, 2800, 3000, 3200, 3500, 4000,
      ],
      meta: {
        unit: "months",
        totalUsers: 30700,
        averageUsers: 2558.33,
      },
    },
  };
  // Graph options
  const optionsOrder = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        title: {
          display: true,
          text: "Users",
        },
      },
    },
  };

  // Prepare data for Chart.js
  const chartDataOrder = {
    labels: fullJsonDataOrder[timeRange].labels,
    datasets: [
      {
        label: `${
          timeRange.charAt(0).toUpperCase() + timeRange.slice(1)
        } Users`,
        data: fullJsonDataOrder[timeRange].userCounts,
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
      },
    ],
  };

  /// by kunal bar chart 21-11-24

  // Static JSON Data
  // const fullJsonDataOrder1 = {
  //   daily: {
  //     timeRange: "daily",
  //     labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  //     userCounts: [100, 200, 150, 300, 250, 400, 350],
  //     meta: {
  //       unit: "days",
  //       totalUsers: 1750,
  //       averageUsers: 250,
  //     },
  //   },
  //   weekly: {
  //     timeRange: "weekly",
  //     labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  //     userCounts: [1200, 1500, 1300, 1700, 1400],
  //     meta: {
  //       unit: "weeks",
  //       totalUsers: 7100,
  //       averageUsers: 1420,
  //     },
  //   },
  //   monthly: {
  //     timeRange: "monthly",
  //     labels: [
  //       "January",
  //       "February",
  //       "March",
  //       "April",
  //       "May",
  //       "June",
  //       "July",
  //       "August",
  //       "September",
  //       "October",
  //       "November",
  //       "December",
  //     ],
  //     userCounts: [
  //       1000, 2000, 1800, 2200, 3000, 2500, 2700, 2800, 3000, 3200, 3500, 4000,
  //     ],
  //     meta: {
  //       unit: "months",
  //       totalUsers: 30700,
  //       averageUsers: 2558.33,
  //     },
  //   },
  // };
  if (!barchartdata) return <div>NO data for pichart</div>; // Show loading state
const getLast7DaysLabelsWithWeekend = () => {
  const labels = [];
  const today = new Date();

  // Start from yesterday
  today.setDate(today.getDate() - 1);

  for (let i = 6; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);

    const dayOfWeek = date.getDay();
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    }); // Format as "MMM DD"

    // if (dayOfWeek === 6) {
    //   labels.push(`Sat ${formattedDate}`);
    // } else if (dayOfWeek === 0) {
    //   labels.push(`Sun ${formattedDate}`);
    // } else {
    labels.push(formattedDate);
    // }
  }

  return labels;
};
  const fullJsonDataOrder1 = {
    daily: {
      timeRange: "Daily",
      labels: getLast7DaysLabelsWithWeekend(),
      userCounts: barchartdata && barchartdata[0]?.counts,
      meta: {
        unit: "Days",
        totalUsers: barchartdata && barchartdata[0]?.totalOrders,
        averageUsers: barchartdata && barchartdata[0]?.averageOrders,
      },
    },
    weekly: {
      timeRange: "Weekly",
      labels:
        barchartdata &&
        barchartdata[1]?.counts.map((_, index) => `Week ${index + 1}`),
      userCounts: barchartdata && barchartdata[1]?.counts,
      meta: {
        unit: "Weeks",
        totalUsers: barchartdata && barchartdata[1]?.totalOrders,
        averageUsers: barchartdata && barchartdata[1]?.averageOrders,
      },
    },
    monthly: {
      timeRange: "Monthly",
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ].slice(0, barchartdata && barchartdata[2]?.counts.length), // Only include labels for available months
      userCounts: barchartdata && barchartdata[2]?.counts,
      meta: {
        unit: "Months",
        totalUsers: barchartdata && barchartdata[2]?.totalOrders,
        averageUsers: barchartdata && barchartdata[2]?.averageOrders,
      },
    },
  };
  // Graph options
  const optionsOrder1 = {
    responsive: true,
  
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },

    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        title: {
          display: true,
          text: "Users",
        },
      },
    },
  };

  // Prepare data for Chart.js
const chartDataOrder1 = {
  labels: fullJsonDataOrder1[timeRangeOrder]?.labels,
  datasets: [
    {
      label: `${
        timeRangeOrder.charAt(0).toUpperCase() + timeRangeOrder.slice(1)
      } Users`,
      data: fullJsonDataOrder1[timeRangeOrder]?.userCounts,
      borderColor: "rgba(75,192,192,1)",
      backgroundColor: new Array(
        fullJsonDataOrder1[timeRangeOrder]?.userCounts?.length
      ).fill("#9966FF"), // Purple color for all bars
    },
  ],
};
// for dynamic select 
const getDateLabel = (rangeType) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.toLocaleString("default", { month: "short" });
  const currentMonthFull = today.toLocaleString("default", { month: "long" });

  // For daily, show last 7 days excluding today
  if (rangeType === "daily") {
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 1); // Set to yesterday (exclude today)
    const endDate = new Date(today);
    endDate.setDate(today.getDate() - 7); // Show 7 days before yesterday

    return `Daily - ${endDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} - ${startDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} ${currentYear}`;
  }

  // For weekly, Jan 1 to current month (abbreviated month)
  if (rangeType === "weekly") {
    const startOfYear = new Date(currentYear, 0, 1); // Jan 1 of current year
    return `Weekly - ${startOfYear.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} to ${currentMonth} ${currentYear}`;
  }

  // For monthly, Jan 1 to current month
  if (rangeType === "monthly") {
    const startOfMonth = new Date(currentYear, today.getMonth(), 1); // 1st day of current month
    return `Monthly - ${startOfMonth.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} to ${today.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} ${currentYear}`;
  }

  // For yearly, Jan 1 to Dec 31 of current year
  if (rangeType === "yearly") {
    const startOfYear = new Date(currentYear, 0, 1); // Jan 1 of current year
    const endOfYear = new Date(currentYear, 11, 31); // Dec 31 of current year
    return `Yearly - ${startOfYear.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} to ${endOfYear.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} ${currentYear}`;
  }
};

  return (
    <React.Fragment>
      <OrderModal
        isOpen={editModal}
        toggle={closeModal}
        editValue={isEdit}
        updateHandler={updateHandler}
      />
      <DeleteModal
        show={deleteModal}
        name={delData?.name}
        onDeleteClick={handleDelete}
        onCloseClick={closeModal}
      />
      <div className="p-2">
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="row">
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Order ID</Label>
              <DebounceInput
                maxLength={40}
                debounceTimeout={300}
                minLength={2}
                name="orderID"
                value={orderId || ""}
                className="form-control field-search"
                placeholder="Type Order ID"
                onChange={(e) => {
                  handlehSetFilter("orderId", e.target.value);
                  setOrderID(e.target.value);
                }}
              />
            </div>
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Order Status</Label>
              <Select
                placeholder={
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    All Statuses
                  </span>
                }
                value={orderStatus || null}
                onChange={(e) => {
                  setOrderStatus(e);
                  setOrderstatusOnly(e.value);
                  handlehSetFilter("orderStatusTwo", e);
                }}
                options={[
                  { label: "All Statuses", value: "" },
                  { label: "Complete", value: "success" },
                  { label: "Refunded", value: "refunded" },
                  { label: "Failed", value: "failed" },
                  // { label: "Pending", value: "pending" },
                ]}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="col-4 mb-3 data-range-filter">
              <Label className="form-label">Filter by Date Range</Label>
              {/* <DateRangePicker
                onChange={(w) => {
                  setValueDatePicker(w);
                  const one = w[0].toISOString();
                  const two = w[1].toISOString();
                  setOrderfilter({
                    ...orderfilter,
                    to: one,
                    from: two,
                  });
                  let fild = {
                    to: one,
                    from: two,
                  };
                  handlehSetFilter("orderfilter", w);
                  handlehSetFilter("orderfiltert", fild);
                }}
                value={valueDatePicker}
                maxDate={new Date()}
                rangeDivider=" - "
                format="dd / MM / yyyy "
                clearIcon={null}
                className="datePicker2"
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
              /> */}

              {/* <DebounceInput
              maxLength={40}
              readOnly={true}
                debounceTimeout={300}
                minLength={2}
                name="orderID"
                value={fromdate&&todate ? moment(fromdate).format("MMM DD, YYYY") +" - "+ moment(todate).format("MMM DD, YYYY"):"MM DD, YYYY - MM DD, YYYY"}
                onClick={()=>setopencaleder(!opencaleder)}
                className="form-control field-search"
                placeholder="MM DD,YYYY - MM DD,YYYY"
                autoComplete="off"
              />


{opencaleder && <> <div className="date-pick-div">
<DateRangePicker
  onChange={item => handleSelect(item)}
  showSelectionPreview={false}
  moveRangeOnFirstSelection={false}
  ranges={[state]}
  direction="horizontal"
  maxDate={new Date()}
  className={"date-range custom-dp"}
/> 
<div className="apply-date-btn-sec">
<button  className="btn apply-date-btn clear-date-btn mr-3" onClick={()=>{setopencaleder(false);setFromdate("");setTodate("")}}>Clear</button> 
<button  className="btn apply-date-btn btn-primary mr-3" onClick={()=>{setopencaleder(false);setFromdate(state.startDate);setTodate(state.endDate)}}>Apply</button> 
</div>
</div>
</> } */}

              {/* <div className="">
                      <div className="rdrDateDisplayWrapper ">
                          <div className="rdrDateDisplay m-0">
                          <div className=" rdrDateInput rdrDateDisplayItem date-input-span"  onClick={()=>setopencaleder(!opencaleder)}>
                          <input readOnly={true} className="form-control search-inp "  placeholder="Early" value={fromdate&&todate ? moment(fromdate).format("MMM DD, YYYY") +" - "+ moment(todate).format("MMM DD, YYYY"):"MM DD, YYYY - MM DD, YYYY"}/>
                          <span className="date-input-icon input-icon-rights cursor-point"> <i className="fa fa-calendar-o"></i></span></div>
                            </div></div>

                            
                       {opencaleder && <> 
                       <div className="date-pick-div"><DateRangePicker
  onChange={item => handleSelect(item)}
  showSelectionPreview={false}
  moveRangeOnFirstSelection={false}
  ranges={[state]}
  direction="horizontal"
  maxDate={new Date()}
  className={"date-range custom-dp"}
/> 
<div className="apply-date-btn-sec">
<button  className="btn apply-date-btn clear-date-btn mr-3" onClick={()=>{setopencaleder(false);setFromdate('');setTodate('')}}>Clear</button> 
<button  className="btn apply-date-btn btn-primary mr-3" onClick={()=>{setopencaleder(false);setFromdate(state.startDate);setTodate(state.endDate)}}>Apply</button> 
</div>
</div>

</> }  
</div> */}

              <DateRangePicker
                onEvent={handleEvent}
                onCallback={handleCallback}
                initialSettings={{
                  showDropdowns: true,
                  autoUpdateInput: false,
                  locale: {
                    cancelLabel: "Clear",
                  },
                  maxDate: new Date(),
                }}
                onApply={handleApply}
                onCancel={handleCancel}
              >
                <input
                  className="form-control field-search"
                  placeholder="Select Range"
                  readOnly
                />
              </DateRangePicker>

              <p style={styles}>{err}</p>
            </div>

            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Customer User Id</Label>
              <DebounceInput
                maxLength={40}
                debounceTimeout={300}
                minLength={2}
                value={cus_name || ""}
                name="filterCustomer"
                className="form-control field-search"
                placeholder="By ID, email or phone"
                onChange={(e) => {
                  setCus_name(e.target.value);
                  handlehSetFilter("cus_name", e.target.value);
                }}
              />
            </div>
            {/* <div className="col-4 mb-3 multiSelect2">
              <Label className="form-label">Filter by Service</Label>
              <ReactMultiSelectCheckboxes
                options={optionData}
                value={serviecNameOnly}
                onChange={(e) => {
                  setCheckMargin("165px");
                  setLoading(true);
                  setServiceNameOnly(e);
                  handlehSetFilter("serviecNameOnly", e);
                }}
                onFocus={() => {
                  setCheckMargin("165px");
                }}
                onBlur={() => {
                  setCheckMargin("0px");
                }}
              />
            </div> */}
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Transaction ID</Label>
              <DebounceInput
                maxLength={40}
                s
                debounceTimeout={300}
                minLength={2}
                value={transactionId}
                name="transactionID"
                className="form-control field-search"
                placeholder="Type Transaction ID"
                onChange={(e) => {
                  setTransactionId(e.target.value);
                  handlehSetFilter("transactionId", e.target.value);
                }}
              />
            </div>

            <div className="col-4 mb-3">
              <Label className="form-label">Filter by user status</Label>
              <Select
                placeholder={
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    All Statuses
                  </span>
                }
                value={status}
                onChange={(e) => {
                  setStatus(e);
                  handlehSetFilter("user_status", e);
                }}
                options={statusDelete}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Processer</Label>
              <Select
                placeholder={
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    All Processers
                  </span>
                }
                value={ProcesserFilter || null}
                onChange={(e) => {
                  // setOrderStatus(e);
                  // setOrderstatusOnly(e.value);
                  setProcesserFilter(e);
                  handlehSetFilter("processor", e);
                }}
                options={[
                  { label: "All Statuses", value: "" },
                  { label: "Card", value: "card" },
                  { label: "stripe", value: "stripe" },
                  { label: "google_pay", value: "google_pay" },
                  { label: "apple_pay", value: "apple_pay " },
                  { label: "Credit", value: "credit" },
                  { label: "link", value: "link" },
                  {
                    label: "apple_in_app_purchase",
                    value: "apple_in_app_purchase",
                  },
                  // { label: "Pending", value: "pending" },
                ]}
                classNamePrefix="select2-selection"
              />
            </div>
          </div>
        </Form>
      </div>
      <Card>
        <CardBody>
          {orderData?.length == 0 && !loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: checkmargin,
              }}
            >
              <h2>No Data Found</h2>
            </div>
          ) : (
            <>
              <>
                <React.Fragment>
                  {/* Parth Roka 18/11/2024 For Orders chart (graph) daily, weekly, monthy */}
                  {/* <div style={{ width: "80%", margin: "auto" }}>
                      <h2>Order Activity</h2>
                      <div style={{ marginBottom: "20px" }}>
                        <select
                          value={timeRangeOrder}
                          onChange={(e) => setTimeRangeOrder(e.target.value)}
                        >
                          <option value="daily">Daily Users</option>
                          <option value="weekly">Weekly Users</option>
                          <option value="monthly">Monthly Users</option>
                        </select>
                      </div>
                      <Line data={chartDataOrder} options={optionsOrder} />
                      <div style={{ marginTop: "20px", textAlign: "left" }}>
                        <h4>Metadata</h4>
                        <p>
                          <strong>Unit:</strong>{" "}
                          {fullJsonDataOrder[timeRange].meta.unit}
                        </p>
                        <p>
                          <strong>Total Users:</strong>{" "}
                          {fullJsonDataOrder[timeRange].meta.totalUsers}
                        </p>
                        <p>
                          <strong>Average Users:</strong>{" "}
                          {fullJsonDataOrder[
                            timeRange
                          ].meta.averageUsers.toFixed(2)}
                        </p>
                      </div>
                    </div> */}
                  {/* kunal jetwani 18/11/2024 For Orders chart (graph) daily, weekly, monthy */}

                  <Card>
                    <CardBody>
                      <div style={{ width: "80%", margin: "auto" }}>
                        <div
                          style={{ marginBottom: "20px" }}
                          className="d-flex justify-content-between"
                        >
                          <h2>Orders</h2>
                          <select
                            style={{ width: "300px", padding: "10px" }}
                            value={timeRangeOrder}
                            onChange={(e) => setTimeRangeOrder(e.target.value)}
                          >
                            <option value="daily">
                              {getDateLabel("daily")} 
                            </option>
                            <option value="weekly">
                              {getDateLabel("weekly")} 
                            </option>
                            <option value="monthly">
                              {getDateLabel("yearly")} 
                            </option>
                          </select>
                        </div>
                        {/* Replace Line with Bar */}
                        <Bar data={chartDataOrder1} options={optionsOrder1} />
                        <div style={{ marginTop: "20px", textAlign: "left" }}>
                          <h4>Meta Data</h4>
                          <p>
                            <strong>Unit:</strong>{" "}
                            {fullJsonDataOrder1[timeRangeOrder].meta.unit}
                          </p>
                          <p>
                            <strong>Total Orders:</strong>{" "}
                            {fullJsonDataOrder1[timeRangeOrder].meta.totalUsers}
                          </p>
                          <p>
                            <strong>Average Orders:</strong>{" "}
                            {fullJsonDataOrder1[
                              timeRangeOrder
                            ].meta.averageUsers.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  {!loading ? (
                    <Card>
                      <CardBody>
                        <div className="col-12 col-md-6 ">
                          <div className="mb-3">
                            <Label className=" ">Records per page</Label>
                            <Select
                              value={{
                                label: `${pageSize} records`,
                                value: pageSize,
                              }}
                              onChange={handlePageSizeChange}
                              className="w-50"
                              options={[
                                { label: "5 records", value: 5 },
                                { label: "10 records", value: 10 },
                                { label: "20 records", value: 20 },
                                { label: "50 records", value: 50 },
                                { label: "100 records", value: 100 },
                              ]}
                            />
                          </div>
                        </div>
                        <Row>
                          <Col xl="12">
                            <div
                              className="table-responsive orders-table-main-div"
                              id="harshu"
                            >
                              <BootstrapTable
                                keyField="id"
                                data={data}
                                columns={EcommerceOrderColumns(toggle)}
                                responsive
                                bordered={false}
                                striped={false}
                                classes={
                                  "table align-middle table-nowrap table-no-check table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div>
                          <div className="row align-items-center p-3">
                            <div className="col-12 col-md-6 text-start mb-2 mb-md-0">
                              Showing{" "}
                              {currentPage <= 1
                                ? 1
                                : (currentPage - 1) * pageSize + 1}{" "}
                              to {Math.min(currentPage * pageSize, countShow)}{" "}
                              of {countShow} entries
                            </div>
                            <div className="col-12 col-md-6 d-flex justify-content-md-end"></div>
                          </div>
                        </div>
                        <div className="pagination pagination-rounded justify-content-center mt-4">
                          <button
                            onClick={handlePreviousLastPage}
                            disabled={currentPage === 1}
                            className={
                              currentPage === page
                                ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                                : "test btn  btn-circle btn-xl rounded-circle m-2"
                            }
                          >
                            {"<<"}
                          </button>
                          <button
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                            className={
                              currentPage === page
                                ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                                : "test btn  btn-circle btn-xl rounded-circle m-2"
                            }
                          >
                            {"<"}
                          </button>
                          {Array.from(
                            { length: endPage - startPage + 1 },
                            (_, index) => startPage + index
                          ).map((page) => (
                            <button
                              key={page}
                              onClick={() => handlePageClick(page)}
                              disabled={currentPage === page}
                              className={
                                currentPage === page
                                  ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                                  : "test btn btn-light  btn-xl rounded-circle m-2"
                              }
                            >
                              {page}
                            </button>
                          ))}
                          {totalPages > startPage + 4}
                          <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            className={
                              currentPage === page
                                ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                                : "test btn  btn-circle btn-xl rounded-circle m-2"
                            }
                          >
                            {">"}
                          </button>
                          <button
                            onClick={handleLastPage}
                            disabled={currentPage === totalPages}
                            className={
                              currentPage === page
                                ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                                : "test btn  btn-circle btn-xl rounded-circle m-2"
                            }
                          >
                            {">>"}
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: checkmargin,
                      }}
                    >
                      <Spinner
                        style={{ width: "2rem", height: "2rem" }}
                        children={false}
                      />
                    </div>
                  )}
                </React.Fragment>
              </>
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

OrderTable.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(OrderTable);
